export const HOME_PAGE_PATH = '/';
export const PROVIDER_PATH = '/insurance/provider/';
export const BASE_SURE_PATH = '/insurance/sure/';
export const ERROR_PAGE_PATH = '/insurance/error';

export const SURE_QUOTE_PATH = `${BASE_SURE_PATH}quote`;
export const SURE_CHECKOUT_PATH = `${BASE_SURE_PATH}checkout`;
export const SURE_CONFIMATION_PATH = `${BASE_SURE_PATH}confirmation`;
export const SURE_RECEIPT_PATH = `${BASE_SURE_PATH}receipt`;
export const WHAT_IS_YOUR_NAME_PATH = `${BASE_SURE_PATH}what-is-your-name`;
export const ADD_ADDRESS_PATH = `${BASE_SURE_PATH}add-new-address`;
export const PLACEHOLDER_PROVIDER_PATH =
  '/images/sure-provider-placeholder.svg';

export const CLOUDINARY_BASE_URL =
  'http://res.cloudinary.com/updater-production/image/upload/';
export const PROVIDERS_BASE_URL =
  'http://res.cloudinary.com/updater-marketing/image/upload/';

export const BASE_PROVIDER = 'Sure';
export const TRUE_STRING = 'true';
export const FALSE_STRING = 'false';

export const ADDRESS_FIELD_MODEL = {
  city: '',
  postalCode: '',
  state: '',
  street: '',
};
