import type { Maybe } from '@updater/consumer-graph';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { useOfferContext } from '@updater/ui-widgets';
import { useReservation } from 'flows/tvi/hooks/use-reservation';

/**
 * Ticket - https://updater.atlassian.net/browse/TVI-1070
 * Growthbook - https://growthbook.prod.updatron.tools/features/consumer_app_tvi_att_ach_discount
 */
export const useOfferInAttAchExperiment = (): boolean => {
  const { providerCode, code: offerCode } = useOfferContext();
  const attAchAutopayDiscountEnabled = useFeatureValue(
    'consumer_app_tvi_att_ach_discount',
    false
  );

  return isOfferInAttAchExperiment(
    providerCode,
    offerCode,
    attAchAutopayDiscountEnabled
  );
};

export const useReservationInAttAchExperiment = (): boolean => {
  const { reservation } = useReservation();
  const providerCode = reservation?.offer?.provider?.id;
  const offerCode = reservation?.offer?.code;
  const attAchAutopayDiscountEnabled = useFeatureValue(
    'consumer_app_tvi_att_ach_discount',
    false
  );

  return isOfferInAttAchExperiment(
    providerCode,
    offerCode,
    attAchAutopayDiscountEnabled
  );
};

export const isOfferInAttAchExperiment = (
  providerCode: Maybe<string> | undefined,
  offerCode: Maybe<string> | undefined,
  experimentEnabled: boolean = false
): boolean => {
  return (
    providerCode === 'att' &&
    offerCode !== 'PB:ATT Internet Air' &&
    experimentEnabled
  );
};
