import { useCallback } from 'react';
import {
  gql,
  useQuery,
  ApolloError,
  WatchQueryFetchPolicy,
  useApolloClient,
} from '@apollo/client';
import { useUserData } from 'flows/core/hooks/use-profile';
import { HomeServicesReservation } from '@updater/consumer-graph';

const HomeServicesReservationQuery = gql`
  query tviHomeServicesReservation($id: ID!) {
    homeServicesReservation(moveId: $id) {
      reservation {
        id
        code
        offer {
          id
          code
          name
          price
          valueScore
          valueTier
          term
          contract {
            cancellationFee {
              amount
              description
            }
            term {
              length
              unit
            }
          }
          pricing {
            charges {
              amount
              description
              term {
                length
                offset
                recurrence
                unit
              }
              type
            }
            description
            price
            term {
              length
              unit
            }
          }
          products {
            internet {
              name
              downloadSpeed {
                unit
                value
              }
              uploadSpeed {
                unit
                value
              }
              type
              features
            }
            phone {
              name
              type
              features
            }
            tv {
              name
              channels {
                count
                lineupCodes
              }
              type
              features
              additionalLanguages
            }
          }
          promotions {
            promotion
            disclaimer
          }
          proximityDisclaimer {
            pricingText
            proximityDisclosure
          }
          highlights
          extendedHighlights
          disclaimer
          introductoryPrice {
            price
            term
            duration
            savingsPercent
          }
          services {
            internet {
              kind
              speed {
                tier
                mbps
              }
            }
            tv {
              additionalLanguages
              kind
              channels {
                count
              }
            }
            phone {
              kind
            }
          }
          provider {
            id
            featured
            exclusive
            featuredKind
            logo
            name
          }
          metadata
          tags
          options {
            installationOptions {
              type
            }
          }
          planDetails {
            instantOn {
              description
              displayName
              steps {
                step
                instruction
              }
              title
            }
          }
        }
        offers {
          id
          code
          name
          price
          valueScore
          valueTier
          term
          contract {
            cancellationFee {
              amount
              description
            }
            term {
              length
              unit
            }
          }
          pricing {
            charges {
              amount
              description
              term {
                length
                offset
                recurrence
                unit
              }
              type
            }
            description
            price
            term {
              length
              unit
            }
          }
          products {
            internet {
              name
              downloadSpeed {
                unit
                value
              }
              uploadSpeed {
                unit
                value
              }
              type
              features
            }
            phone {
              name
              type
              features
            }
            tv {
              name
              channels {
                count
                lineupCodes
              }
              type
              features
              additionalLanguages
            }
          }
          introductoryPrice {
            price
            term
            duration
            savingsPercent
          }
          services {
            internet {
              kind
              speed {
                tier
                mbps
              }
            }
            tv {
              additionalLanguages
              kind
              channels {
                count
              }
            }
            phone {
              kind
            }
          }
          provider {
            id
            featured
            exclusive
            featuredKind
            logo
            name
          }
          metadata
          tags
          options {
            installationOptions {
              type
            }
          }
          planDetails {
            instantOn {
              description
              displayName
              steps {
                step
                instruction
              }
              title
            }
          }
        }
        status
        user {
          email
          firstName
          lastName
          phone
        }
        toAddress {
          street
          unit
          city
          state
          postalCode
        }
        preferences {
          flow
        }
        createdAt
      }
    }
  }
`;

export type Reservation = HomeServicesReservation & {
  pricing?: {
    centralRegion?: boolean;
  };
};

export type ReservationQueryResult = {
  status: 'failed' | 'loading' | 'loaded';
  reservation: Reservation | null;
  error?: ApolloError;
  pricing?: {
    centralRegion?: boolean;
  };
};

/**
 * If you update this value, update it in att happy-path.spec too.
 */
export const centralRegionStates = [
  'AL',
  'AR',
  'FL',
  'GA',
  'IL',
  'IN',
  'KY',
  'LA',
  'MI',
  'MS',
  'SC',
  'TN',
  'IN',
];

const CENTRAL_REGION_CHANGE_DATE = new Date('2022-02-08T16:30:00Z');

export function useReservationQuery({
  currentMoveId,
  fetchPolicyInitiated,
}: {
  currentMoveId: string;
  fetchPolicyInitiated: WatchQueryFetchPolicy;
}) {
  return useQuery(HomeServicesReservationQuery, {
    variables: {
      id: currentMoveId,
    },
    fetchPolicy: fetchPolicyInitiated,
    skip: !currentMoveId,
  });
}

/*
 * This hook provides a callback that lets you clear the current reservation
 * from the Apollo cache, if one exists. Useful when making a reservation with
 * a different endpoint and ensuring that outdated values are not displayed.
 */
export function useClearCachedReservation() {
  const { user } = useUserData();
  const currentMoveId = user?.currentMove?.id || '';

  const client = useApolloClient();
  const reservationObject = useReservationQuery({
    currentMoveId,
    fetchPolicyInitiated: 'cache-only',
  });

  return useCallback(() => {
    if (reservationObject) {
      const reservationId = client.cache.identify({
        // This spread fixes the typing
        // https://github.com/apollographql/apollo-tooling/issues/2151#issuecomment-719863805
        ...reservationObject?.data?.homeServicesReservation?.reservation,
      });

      if (reservationId) {
        client.cache.evict({ id: reservationId });
      }
    }
  }, [client.cache, reservationObject]);
}

export function useReservation(
  fetchPolicyInitiated: WatchQueryFetchPolicy = 'cache-first'
): ReservationQueryResult {
  const { user } = useUserData();
  const currentMoveId = user?.currentMove?.id || '';

  const { loading, error, data } = useReservationQuery({
    currentMoveId,
    fetchPolicyInitiated,
  });

  const providerId =
    data?.homeServicesReservation?.reservation?.offer?.provider?.id;

  // saving the provider id locally to access within the TVI Formstore
  if (
    providerId &&
    localStorage.getItem('tviCurrentProviderId') !== providerId
  ) {
    localStorage.setItem('tviCurrentProviderId', providerId);
  }

  if (!currentMoveId || loading) {
    return {
      status: 'loading',
      reservation: null,
    };
  }

  if (!data) {
    return {
      status: 'failed',
      reservation: null,
      error,
    };
  }

  if (data?.homeServicesReservation?.reservation === null) {
    return {
      status: 'loaded',
      reservation: null,
    };
  }

  const metadata = data.homeServicesReservation?.reservation?.offer?.metadata;
  const createdAt = data.homeServicesReservation?.reservation?.createdAt;

  return {
    status: 'loaded',
    reservation: {
      ...data.homeServicesReservation?.reservation,
      offer: {
        ...data.homeServicesReservation?.reservation?.offer,
        metadata: metadata ? JSON.parse(metadata) : metadata,
      },
      pricing: {
        centralRegion:
          centralRegionStates.includes(
            user?.currentMove?.toAddress?.state || ''
          ) && new Date(createdAt) > CENTRAL_REGION_CHANGE_DATE,
      },
    },
  };
}

export type UseReservationType = ReturnType<typeof useReservation>;
